/** @jsx jsx */
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { jsx } from "@emotion/core"

const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav
      className="pagination d-flex justify-content-between"
      role="navigation"
      css={theme => ({
        color: theme.colors.secondary,
        fontSize: "16px",
      })}
    >
      <div>
        {previousPagePath && (
          <Link
            className="font-weight-semi-bold"
            to={previousPagePath}
            rel="prev"
          >
            Page précédente
          </Link>
        )}
      </div>
      {/*{numberOfPages > 1 && <div className="pagination-location">Page {humanPageNumber} of {numberOfPages}</div>}*/}
      <div>
        {nextPagePath && (
          <Link className="font-weight-semi-bold" to={nextPagePath} rel="next">
            Page suivante
          </Link>
        )}
      </div>
    </nav>
  )
}

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Pagination
