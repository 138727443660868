/** @jsx jsx */
import { StaticQuery, graphql, Link } from "gatsby"
import { Badge } from "react-bootstrap"
import { jsx, css } from "@emotion/core"
import SliderReviews from "../Slider/SliderReviews"

export default function Sidebar() {
  return (
    <StaticQuery
      query={graphql`
        query CategoriesQuery {
          allStrapiCategory {
            edges {
              node {
                id
                name
                slug
              }
            }
          }
        }
      `}
      render={data => (
        <div
          className="mt-4 mt-lg-0"
          css={css`
            position: sticky;
            margin-left: auto;
            @media (min-width: 992px) {
              max-width: 300px;
            }
          `}
        >
          <div
            className="text-center mb-4 text-white"
            css={theme => css`
              padding: 30px 10px;
              border-radius: 4px;
              background-color: ${theme.colors.primary};
            `}
          >
            <h3>Simulassur</h3>
            <p>Augmentez votre chiffre d'affaires avec l'Assurance de Prêt</p>
            <Link
              className="btn btn-secondary btn-sm d-inline-block text-uppercase m-2"
              to="/inscription/"
            >
              Devenir partenaire
            </Link>
          </div>
          <div>
            <h3>Catégories</h3>
            <p>
              <Badge pill variant="gray" className="mb-1 mr-1">
                <Link to={`/blog/`}>Tout</Link>
              </Badge>
              {data.allStrapiCategory.edges.map(category => (
                <Badge
                  key={category.node.id}
                  pill
                  variant="gray"
                  className="mb-1 mr-1"
                >
                  <Link to={`/blog/${category.node.slug}`}>
                    {category.node.name}
                  </Link>
                </Badge>
              ))}
            </p>
          </div>
          <div>
            <h3>Avis</h3>
            <SliderReviews />
          </div>
        </div>
      )}
    />
  )
}
