/** @jsx jsx */
import { jsx } from "@emotion/core"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Card from "react-bootstrap/Card"

const SliderReviews = () => {
  const settings = {
    className: "slick-reviews",
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
  }

  return (
    <Card
      className="p-4 rounded-lg shadow"
      css={{ paddingBottom: "40px !important" }}
    >
      <Slider {...settings}>
        <div>
          <div
            css={{ fontSize: "0.875rem", lineHeight: 1 }}
            className="text-secondary font-weight-normal mb-2"
          >
            Ingénieurie Concept Finance
          </div>
          <p className="mb-2" css={{ fontSize: "0.875rem" }}>
            L'outil SimulAssur est un appoint indispensable lors de mes
            entretiens.
          </p>
          <h6
            css={{ fontSize: "0.875rem" }}
            className="text-right font-weight-semi-bold mb-0"
          >
            Patrick Cuvelier
          </h6>
        </div>
        <div>
          <div
            css={{ fontSize: "0.875rem", lineHeight: 1 }}
            className="text-secondary font-weight-normal mb-2"
          >
            IOB Consulting
          </div>
          <p className="mb-2" css={{ fontSize: "0.875rem" }}>
            Très simple d'utilisation, on peut faire un devis en 5 minutes top
            Chrono !
          </p>
          <h6
            css={{ fontSize: "0.875rem" }}
            className="text-right font-weight-semi-bold mb-0"
          >
            Marie Souchard
          </h6>
        </div>
      </Slider>
    </Card>
  )
}

export default SliderReviews
