/** @jsx jsx */
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { css, jsx } from "@emotion/core"

import Pagination from "../components/Pagination"
import Layout from "../components/Layout"
import { Col, Container, Row } from "react-bootstrap"
import Section from "../components/Section"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Sidebar from "../components/Sidebar"

const BlogTemplate = ({ data, pageContext }) => {
  const articles = data.allStrapiArticle.edges

  const createExcerpt = value => {
    return value.replace(/<[^>]+>/g, "").slice(0, 210) + "..."
  }

  const rows = [...Array(Math.ceil(articles.length / 2))]
  const productRows = rows.map((row, idx) =>
    articles.slice(idx * 2, idx * 2 + 2)
  )
  const content = productRows.map((row, idx) => (
    <Row key={idx} className="mb-lg-5">
      {row.map(article => (
        <article key={article.node.id} className="col-lg-6 mb-4 mb-lg-0">
          <Link to={`/blog/${article.node.slug}/`}>
            {article.node.image ? (
              <Img
                className="w-100"
                fixed={article.node.image.childImageSharp.fixed}
                css={css`
                  background-color: rgb(237, 241, 245);
                  border-radius: 4px;
                `}
              />
            ) : null}

            <div
              css={theme => ({
                color: theme.colors.primary,
                fontWeight: "600",
              })}
              className="my-2"
            >
              {article.node.title}
            </div>
          </Link>

          <div
            className="d-flex mb-2"
            css={css`
              font-size: 0.875rem;
              color: rgba(51, 51, 51, 0.7);
            `}
          >
            {article.node.category ? (
              <div className="border-right pr-2">
                {article.node.category.name}
              </div>
            ) : null}
            <div className="ml-2">{article.node.date}</div>
          </div>

          <p
            css={css`
              font-size: 0.938rem;
            `}
            dangerouslySetInnerHTML={{
              __html: createExcerpt(article.node.content),
            }}
          />
        </article>
      ))}
    </Row>
  ))

  return (
    <Layout>
      <SEO title="Actualités" description="Profitez de l’expertise de Simulassur et devenez incollable sur les actualités assurances" />

      <Section css={{ marginTop: "60px" }} className="py-5">
        <Container>
          <h1 className="mb-0">Actualités</h1>
        </Container>
      </Section>
      <Section className="pt-0">
        <Container>
          <Row>
            <Col lg={8}>
              {content}
              <Pagination pageContext={pageContext} />
            </Col>
            <Col lg={4}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

BlogTemplate.propTypes = {
  data: PropTypes.shape({
    allStrapiArticle: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
}

export default BlogTemplate

// This page query loads all articles sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query BlogByPage($limit: Int!, $skip: Int!, $slug: String) {
    allStrapiArticle(
      sort: { order: DESC, fields: [date] }
      limit: $limit
      skip: $skip
      filter: { category: { slug: { eq: $slug } }, status: {eq: true} }
    ) {
      edges {
        node {
          id
          image {
            childImageSharp {
              fixed(width: 365, height: 185) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          title
          content
          slug
          category {
            name
          }
          date(formatString: "DD/MM/YYYY")
        }
      }
    }
  }
`
